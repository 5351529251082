import $ from "./tc/tc-query-selector";
import TcDropdown from "./tc/tc-dropdown";
import TcMenu from "./tc/tc-menu";
import "./tc/tc-file-handler";
import "babel-polyfill";
import moment from "moment";
import MomentTZ from "moment-timezone";
import { checkNotifications } from "./tc/tc-check-notifications";

import isWebview from "is-ua-webview";

const languageDropdown = new TcDropdown(".js-language-select");

moment.locale("nl");

if (typeof NodeList.prototype.forEach !== "function") {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

TcMenu();

checkNotifications();

// Found in ./pages/terms.js too, because the common.app.js is not loaded on that page.
window.onload = function () {
  if (isWebview(navigator.userAgent)) {
    document.getElementsByClassName("smartbanner")[0].style.display = "none";
  }
};
